.parentDetails {
  box-shadow: 0px 4px 24px rgba(156, 171, 194, 0.2);
  border-radius: 31px;
  padding: 50px;
  font-size: 20px !important;
  font-weight: 400 !important;
  margin: 100px auto 0 auto;
}

.parentHeading {
  font-size: 30px;
  font-weight: bold;
}

.detailLabel {
  font-size: 20px;
  font-weight: 500;
}

@media only screen and (max-width: 855px) {
  .parentDetails {
    width: 90% !important;
  }
}

body {
  background-image: url("background.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.flexbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cards {
  border-radius: 25px !important;
  border: 2px solid white !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}

.inputs {
  border-radius: 25px !important;
  width: 100% !important;
}
.loginButton {
  background-color: #082152 !important;
  border-radius: 25px !important;
}

@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Montserrat', sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.mt-7 {
  margin-top: 7rem !important;
}

.page-heading {
  color: #4b5e7e;
  font-weight: 800;
  font-size: 2.75rem;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar {
  background-color: white !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 6px 6px 0px;
}

#dropdown-title {
  color: rgb(75, 94, 126);
}

#dropdown-title:hover {
  color: black;
}

#dropdown-item {
  color: rgb(75, 94, 126);
  text-align: center;
}

#dropdown-item:hover {
  color: black;
  background-color: rgb(255, 255, 255);
}

#dropdown-item.active {
  color: black;
  background-color: rgb(255, 255, 255);
}

.navbar-item {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 30px;
  display: inline-block !important;
  white-space: nowrap;
  -webkit-align-self: center;
          align-self: center;
}

#navbar-item-signin {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  white-space: nowrap;
  word-spacing: 0px;
  text-decoration: none;
}

#navbar-item-signin:hover {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-btn__book {
  -webkit-align-self: center;
          align-self: center;
  height: 35px;
  background-color: rgb(250, 179, 0) !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 1px 2px 0px;
  border-radius: 6px !important;
}

.navbar-btn__book:hover {
  background-color: rgb(75, 94, 126) !important;
}

.navbar-btn__book-text {
  font-size: 18px;
  text-align: start;
  white-space: nowrap;
  background-origin: padding-box;
  color: white;
}

.img-signin {
  -webkit-align-self: center;
          align-self: center;
}

.passwordResetLink {
  text-align: start;
}

.loginButton {
  background: #4b5e7e !important;
  border-color: #4b5e7e !important;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  width: -webkit-max-content;
  width: max-content;
  -webkit-justify-content: var(--label-align);
          justify-content: var(--label-align);
  min-width: 100%;
}
.loginButton:hover {
  background-color: rgb(75, 94, 126, 0.9);
  border-color: rgb(75, 94, 126, 0.9);
}

.authLineBreak {
  background-color: black;
  color: rgb(0, 0, 0);
  font-weight: 900;
  width: 100%;
  height: 1px;
}

.registerButton {
  background-color: #4b5e7e !important;
  border-color: #4b5e7e !important;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  width: -webkit-max-content;
  width: max-content;
  -webkit-justify-content: var(--label-align);
          justify-content: var(--label-align);
  min-width: 100%;
}
.generatePasswordButton {
  background-color: #4b5e7e !important;
  border-color: #4b5e7e !important;
  text-align: center;
  -webkit-align-items: center;
          align-items: center;
  box-sizing: border-box;
  width: -webkit-max-content;
  width: max-content;
  -webkit-justify-content: var(--label-align);
          justify-content: var(--label-align);
  min-width: 100%;
}

.loginModal {
  width: 100%;
  height: 100%;
}

/* 
For reference

.modal .modal-dialog {
  width: 100% !important;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal .modal-content {
  width: 100% !important;
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal .modal-body {
  overflow-y: auto;
}
.modal-header {
  border-bottom: none;
}

button.close {
  height: min-content !important;
  width: min-content !important;
  margin-right: 3% !important;
} */

.myAccountCard {
  margin-top: 10em;
  margin-left: auto;
  margin-right: auto;
}
.profileHolder {
  width: 100%;
  height: 100% !important;
  background-color: #4b5e7e !important;
}

.cameraIcon {
  margin: 2em;
}

.publicProfileButton {
  -webkit-appearance: auto;
          appearance: auto;
  text-rendering: auto;
  background-color: transparent !important;
  border-color: white !important;
  height: 32px;
  padding: 0 16px !important;
  width: -webkit-max-content;
  width: max-content;
  border-width: 1px;
  border-style: solid;
  border-radius: 0 !important;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
}

.publicProfileButton:hover,
.publicProfileButton:visited,
.publicProfileButton:focus {
  outline: none !important;
  box-shadow: none;
  color: rgb(255, 255, 255, 0.7) !important;
  border-color: rgb(255, 255, 255, 0.7) !important;
  border-color: white;
}

.profileHolderUsername {
  font-style: normal;
  font-feature-settings: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 20px;
  line-height: 35px;
  font-family: avenir-lt-w01_35-light1475496, sans-serif;

  color: rgb(255, 255, 255);

  white-space: nowrap;
}

.toggleButton {
  background-color: transparent !important;
  border: none !important;
}

.toggleButton::after {
  display: none !important;
}

.profileHolderEditProfileName {
  background: transparent !important;
  color: white !important;
  border: 0 !important;
  box-shadow: none;
  border-bottom: solid white !important;
}

.profileHolderSaveButton:hover {
  background: rgb(255, 255, 255, 0.4);
}

.resetForm{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.errorMsg{
    color: red;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    text-shadow: 1px 1px rgba(250,250,250,.3);
}
.submit{
    background-color: #4b5e7e !important;
    border-color: #4b5e7e !important;
}
.childAlert{
    width: 100%  !important;
  height: 100%  !important;
  margin: 0  !important;
  padding: 0  !important;
 

    display: -webkit-flex;
 

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    overflow-y: auto !important;
}

.childrenContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.childContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-column-gap: 40px;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  grid-row-gap: 30px;
  row-gap: 30px;
}

.childCard {
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  box-shadow: 0px 4px 24px rgba(156, 171, 194, 0.2);
  border-radius: 31px;
  min-width: 280px;
  cursor: pointer;
  border: 2px solid transparent;
}

.childCard h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
}

.childCard p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}

.childButton {
  border: 1px solid #082152;
  border-radius: 168px;
  background-color: white;
  padding: 8px;
  color: #082152;
  font-weight: bold;
  font-size: 16px;
  min-width: 308px;
}

.proceedMenuButton {
  background-color: #082152;
  padding: 8px 32px;
  margin: 0px 4px;
  color: white;
  min-width: 0px;
}

.proceedMenuButton:disabled {
  background: #c4c4c4;
  border: 1px solid #c4c4c4;
}

.backMenuButton {
  padding: 8px 32px;
  margin: 0px 4px;
  min-width: 0px;
}

.childCheckbox {
  display: none;
}

.childCheckbox:checked + .childCard {
  border: 2px solid #082152;
}

.childHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;

  color: #082152;
}

@media only screen and (max-width: 768px) {
  .childContainer {
    grid-template-columns: 1fr;
  }
}

.row1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
  -webkit-align-items: center;
          align-items: center;
  width: 100% !important;
  grid-gap: 20px;
  gap: 20px;
}
.row2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100% !important;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
  grid-gap: 20px;
  gap: 20px;
}
.google-login-button {
  background-color: #f8f9fa !important;
}
.welcome {
  width: 85px;
  height: 20px;
  top: 50px;
  left: 326.11px;
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.create {
  width: 319px;
  height: 37px;
  top: 69px;
  left: 213.11px;
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
}

.already {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.signupCard {
  max-width: 46rem !important;
  width: 80%;
}

.flag-dropdown {
  top: auto !important;
  bottom: 28% !important;
}
.facebook-div {
  text-align: center;
}
.facebook-btn {
  margin: auto !important;
  display: block !important;
  width: 250px !important;
  height: 40px !important;
  background: #1877f2 !important;
  border-radius: 5px !important;
  color: white !important;
  font-size: 16px !important;
  text-align: center !important;
  font-weight: 800 !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.google-div {
  text-align: center;
}
.google-btn {
  margin: auto !important;
  display: block !important;
  background: white !important;
  color: #444 !important;
  width: 250px !important;
  border-radius: 5px !important;
  border: thin solid #888 !important;
  box-shadow: 1px 1px 1px grey !important;
  white-space: nowrap !important;
  height: 40px !important;
  font-size: 14px !important;
  font-weight: bold !important;

  font-family: "Roboto", sans-serif !important;
}

@media only screen and (max-width: 784px) {
  .row1 {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .row2 {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

body {
  background-image: url(/static/media/background.838065ef.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.flexbox {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.cards {
  border-radius: 25px !important;
  border: 2px solid white !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 10px; /* Added */
}

.inputs {
  border-radius: 25px !important;
  width: 100% !important;
}
.loginButton {
  background-color: #082152 !important;
  border-radius: 25px !important;
}

.questionContainer {
  padding: 37px;
  box-shadow: 0px 4px 24px rgba(156, 171, 194, 0.2);
  font-size: 18px;
  width: 75%;
}

.questionText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* margin-bottom: 0; */
}

.childTab {
  background: rgba(118, 118, 128, 0.12);
  border-radius: 8px;
  padding: 4px;
}

.childPill {
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.pillActive {
  background: white;
  cursor: auto;
}

.parentDetails {
  box-shadow: 0px 4px 24px rgba(156, 171, 194, 0.2);
  border-radius: 31px;
  padding: 50px;
  font-size: 20px !important;
  font-weight: 400 !important;
  margin: 100px auto 0 auto;
}

.parentHeading {
  font-size: 30px;
  font-weight: bold;
}

.detailLabel {
  font-size: 20px;
  font-weight: 500;
}

@media only screen and (max-width: 855px) {
  .parentDetails {
    width: 90% !important;
  }
}

.not-found{
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
}
.not-found-heading{
    font-size: 4rem;
    font-weight: 600;
    color: #0e2152;
}
.not-found-msg{
    font-size: 1.3rem;
}
.not-found-btn{
    background-color: #0e2152 !important;
    border: none !important;
    margin: 2rem 0.5rem;
    padding: 0.5rem 2rem !important;
    width: 100px !important;
}

.row1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  width: 100% !important;
  gap: 20px;
}
.row2 {
  display: flex;
  flex-direction: row;
  width: 100% !important;
  align-items: center;
  justify-content: space-between !important;
  gap: 20px;
}
.google-login-button {
  background-color: #f8f9fa !important;
}
.welcome {
  width: 85px;
  height: 20px;
  top: 50px;
  left: 326.11px;
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.create {
  width: 319px;
  height: 37px;
  top: 69px;
  left: 213.11px;
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
}

.already {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.signupCard {
  max-width: 46rem !important;
  width: 80%;
}

.flag-dropdown {
  top: auto !important;
  bottom: 28% !important;
}
.facebook-div {
  text-align: center;
}
.facebook-btn {
  margin: auto !important;
  display: block !important;
  width: 250px !important;
  height: 40px !important;
  background: #1877f2 !important;
  border-radius: 5px !important;
  color: white !important;
  font-size: 16px !important;
  text-align: center !important;
  font-weight: 800 !important;
  font-family: Arial, Helvetica, sans-serif !important;
}
.google-div {
  text-align: center;
}
.google-btn {
  margin: auto !important;
  display: block !important;
  background: white !important;
  color: #444 !important;
  width: 250px !important;
  border-radius: 5px !important;
  border: thin solid #888 !important;
  box-shadow: 1px 1px 1px grey !important;
  white-space: nowrap !important;
  height: 40px !important;
  font-size: 14px !important;
  font-weight: bold !important;

  font-family: "Roboto", sans-serif !important;
}

@media only screen and (max-width: 784px) {
  .row1 {
    flex-direction: column;
  }

  .row2 {
    flex-direction: column;
  }
}

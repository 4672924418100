.questionContainer {
  padding: 37px;
  box-shadow: 0px 4px 24px rgba(156, 171, 194, 0.2);
  font-size: 18px;
  width: 75%;
}

.questionText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  /* margin-bottom: 0; */
}

.childTab {
  background: rgba(118, 118, 128, 0.12);
  border-radius: 8px;
  padding: 4px;
}

.childPill {
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.pillActive {
  background: white;
  cursor: auto;
}

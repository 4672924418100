.resetForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.errorMsg{
    color: red;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    text-shadow: 1px 1px rgba(250,250,250,.3);
}
.submit{
    background-color: #4b5e7e !important;
    border-color: #4b5e7e !important;
}
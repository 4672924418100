.childrenContainer {
  display: flex;
  justify-content: center;
}

.childContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  column-gap: 40px;
  row-gap: 30px;
}

.childCard {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 24px rgba(156, 171, 194, 0.2);
  border-radius: 31px;
  min-width: 280px;
  cursor: pointer;
  border: 2px solid transparent;
}

.childCard h2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
}

.childCard p {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
}

.childButton {
  border: 1px solid #082152;
  border-radius: 168px;
  background-color: white;
  padding: 8px;
  color: #082152;
  font-weight: bold;
  font-size: 16px;
  min-width: 308px;
}

.proceedMenuButton {
  background-color: #082152;
  padding: 8px 32px;
  margin: 0px 4px;
  color: white;
  min-width: 0px;
}

.proceedMenuButton:disabled {
  background: #c4c4c4;
  border: 1px solid #c4c4c4;
}

.backMenuButton {
  padding: 8px 32px;
  margin: 0px 4px;
  min-width: 0px;
}

.childCheckbox {
  display: none;
}

.childCheckbox:checked + .childCard {
  border: 2px solid #082152;
}

.childHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;

  color: #082152;
}

@media only screen and (max-width: 768px) {
  .childContainer {
    grid-template-columns: 1fr;
  }
}

.myAccountCard {
  margin-top: 10em;
  margin-left: auto;
  margin-right: auto;
}
.profileHolder {
  width: 100%;
  height: 100% !important;
  background-color: #4b5e7e !important;
}

.cameraIcon {
  margin: 2em;
}

.publicProfileButton {
  appearance: auto;
  text-rendering: auto;
  background-color: transparent !important;
  border-color: white !important;
  height: 32px;
  padding: 0 16px !important;
  width: max-content;
  border-width: 1px;
  border-style: solid;
  border-radius: 0 !important;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
}

.publicProfileButton:hover,
.publicProfileButton:visited,
.publicProfileButton:focus {
  outline: none !important;
  box-shadow: none;
  color: rgb(255, 255, 255, 0.7) !important;
  border-color: rgb(255, 255, 255, 0.7) !important;
  border-color: white;
}

.profileHolderUsername {
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 20px;
  line-height: 35px;
  font-family: avenir-lt-w01_35-light1475496, sans-serif;

  color: rgb(255, 255, 255);

  white-space: nowrap;
}

.toggleButton {
  background-color: transparent !important;
  border: none !important;
}

.toggleButton::after {
  display: none !important;
}

.profileHolderEditProfileName {
  background: transparent !important;
  color: white !important;
  border: 0 !important;
  box-shadow: none;
  border-bottom: solid white !important;
}

.profileHolderSaveButton:hover {
  background: rgb(255, 255, 255, 0.4);
}

.childAlert{
    width: 100%  !important;
  height: 100%  !important;
  margin: 0  !important;
  padding: 0  !important;
 

    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
}
